import { useApi } from '~/composables/useApi';
import { useCart } from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { computed, onMounted, ComputedRef, ref, readonly, DeepReadonly } from "@nuxtjs/composition-api";
import useShippingProvider from '~/modules/checkout/composables/useShippingProvider';
import moment from 'moment';
import { merge } from 'lodash-es';
import { Cart } from '~/modules/GraphQL/types';

export interface ProductDeliveryInfo {
  standard: StandardDelivery,
  express: ExpressDelivery, 
  postal: PostalDelivery
}

export interface StandardDelivery {
  delivery_group_name: String,
  earliest_delivery_date: number,
  fulfillment_agency_name: String,
  earliest_delivery_date_display: String,
  free_delivery_min_subtotal: number
  delivery_fee_type: String
}
export interface ExpressDelivery {
  can_express: Boolean,
  delivery_group_name: String,
  earliest_delivery_date: number,
  earliest_timeslot: String
  fulfillment_agency_name: String,
  earliest_delivery_date_display: String,
  earliest_timeslot_display: String
}

export interface PostalDelivery {
  delivery_group_name: String
  earliest_delivery_date: number
  fulfillment_agency_name: String
  postal_estimated_shipping_day: String
}

export interface CartDeliveryGroup {
  delivery_groups: [DeliveryGroup]
}

export interface CheckoutDeliveryGroups {
  delivery_groups: [CheckoutDeliveryGroup]
}

export interface CheckoutDeliveryGroup {
  standard: StandardCheckoutDeliveryGroup
  express: ExpressCheckoutDeliveryGroup
  items: [DeliveryGroupItem]
  nogo: Boolean
}

export interface StandardCheckoutDeliveryGroup {
  delivery_group_id: number
  delivery_group_name: String
  fulfillment_agency_name: String
  free_delivery_min_subtotal: number
  delivery_charge: String
  available_dates: [String]
  mattress_disposal: Boolean
  timeslots: [Timeslot]
}

export interface ExpressCheckoutDeliveryGroup {
  delivery_group_id: number
  delivery_group_name: String
  fulfillment_agency_name: String
  earliest_delivery_date: String
  delivery_charge: String
  mattress_disposal: Boolean
  timeslots: [Timeslot]
}

export interface Timeslot {
  timeslot_start: String
  timeslot_end: String
  is_closed: Boolean
}

export interface DeliveryGroup {
  delivery_group_id: number
  delivery_group_name: String
  fulfillment_agency_name: String
  plan_type: String
  free_delivery_min_subtotal: number
  first_item_charge: number
  available_dates: [String]
  items: [DeliveryGroupItem]
}

export interface DeliveryGroupItem {
  item_id: String
  product_id: String
  product_name: String
  earliest_available_date: EarliestAvailableDate
}

export interface EarliestAvailableDate {
  standard: String
  express: String
}

export interface SaveQuoteDeliveryInfo {
  status: Boolean
}

export interface SaveQuoteRemarksInfo {
  error: Boolean
}

export interface DeliveryGroupTimeslots {
  timeslots: [Timeslot]
}

export interface DeliveryQuote {
  delivery_date: String
  delivery_time_start: String
  delivery_time_end: String
  remarks: String
}

export interface DeliveryQuoteValidation {
  status: number
}

export const useFtDelivery = () => {
  const { query } = useApi();
  const { cart, load: loadCart, loading: loadingCart, setCart, mergeCart } = useCart();

  const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
  const { save: saveShippingMethod } = useShippingProvider();

  onMounted(() => {
    if (totalItems.value && !cart.value.id) {
      loadCart();
    }
  });
  
  const cart_id = computed(() => cart?.value?.id); 
  
  const loading = ref(false);

  const getProductDelivery = async (product) =>{
    loading.value = true;
    const variables = ref({
      product_id: product.uid, 
      qty: product.qty ? product.qty : 1
    })

    if (product.type=="ConfigurableProduct"){
      variables.value = {...variables.value, ...{configurable_child_product_id: product.configurable_child_product_id}}
    }

    if (product.type=="BundleProduct"){
      let option_string: String = Buffer.from(JSON.stringify(product.selected_options)).toString('base64');
      // option_string = option_string.split("\"").join("")
      variables.value = {...variables.value, ...{selected_options: option_string}}
    }

    if (totalItems.value){
      variables.value = {...variables.value, ...{cart_id: cart.value.id}}
    }

    const myGraphqlQuery = `
    query getProductDeliveryInfo($product_id: String, $qty: Int, $cart_id: String, $selected_options: String, $configurable_child_product_id: String){
      getProductDeliveryInfo(product_id: $product_id, qty: $qty, cart_id: $cart_id, selected_options: $selected_options, configurable_child_product_id: $configurable_child_product_id){
        standard{
          delivery_group_name
          fulfillment_agency_name
          earliest_delivery_date
          free_delivery_min_subtotal
          delivery_fee_type
        }
        express{
          delivery_group_name
          fulfillment_agency_name
          earliest_delivery_date
          earliest_timeslot
        }
        postal{
          delivery_group_name
          fulfillment_agency_name
          postal_estimated_shipping_day
        }
      }
    }
    `;
    const { data } = await query<{ getProductDeliveryInfo: ProductDeliveryInfo }>(myGraphqlQuery, variables.value);
    const {getProductDeliveryInfo} = data;
    loading.value = false;
    // getProductDeliveryInfo.standard.earliest_delivery_date_display = getFormattedDate(getProductDeliveryInfo?.standard?.earliest_delivery_date ?? 0);
    
    const getStandardDeliveryGroupName = () => getProductDeliveryInfo?.standard?.delivery_group_name ?? '';
    const getStandardDeliveryDateDisplay = () => getFormattedDate(data.getProductDeliveryInfo?.standard?.earliest_delivery_date ?? 0);
    
    const getExpressDeliveryGroupName = () => getProductDeliveryInfo?.express?.delivery_group_name ?? '';
    // const getCanExpress = () => (getProductDeliveryInfo?.express?.delivery_group_name != null && getProductDeliveryInfo.express.delivery_group_name != '') ? true : false;
    const getCanExpress = () => getProductDeliveryInfo?.express?.delivery_group_name ? true : false;
    const getExpressDeliveryEaliestTimeslot = () => getProductDeliveryInfo?.express?.earliest_timeslot ?? '';
    const getExpressDeliveryDate =  getProductDeliveryInfo?.express?.earliest_delivery_date ?? 0;
    const expressDifferenceInDay = () => {
      const now = moment().format("YYYY-MM-DD");
      if (getCanExpress()){
        const expressEarliestDate = moment(getExpressDeliveryDate*1000, 'x').format("YYYY-MM-DD");
        return moment(expressEarliestDate).diff(now, 'days'); //calcualte different between now and express earliest date
      }
    };
    

    const getExpressDeliveryDayDisplay = ()=>{
      if (getCanExpress){
        if (expressDifferenceInDay() === 0){
          return "Today";
        }
        if (expressDifferenceInDay() === 1){
          return "Tomorrow";
        }
        else{
          return moment(getExpressDeliveryDate*1000, 'x').format("dddd, D MMMM YYYY");
        }
      }
    };

    return {
      data,
      loading,
      getStandardDeliveryGroupName,
      getStandardDeliveryDateDisplay,
      getExpressDeliveryGroupName,
      getCanExpress,
      getExpressDeliveryEaliestTimeslot,
      getExpressDeliveryDayDisplay
    };
  }

  const cartDeliveryGroup = ref<CartDeliveryGroup | null>();

  const loadingCartDeliveryGroup = ref(true);

  const getCartDeliveryGroup = async () => {

    if (!totalItems.value){
      return;
    }
    const graphqlQuery = `
      query getCartDeliveryGroup($cart_id: String){
          getCartDeliveryGroup(cart_id: $cart_id) {
            delivery_groups {
              delivery_group_id
              delivery_group_name
              fulfillment_agency_name
              plan_type
              free_delivery_min_subtotal
              first_item_charge
              items {
                item_id
                product_id
                product_name
                earliest_available_date {
                  standard
                  express
                }
              }
            }
          }
        }
    `;

    const { data, errors } = await query<{ getCartDeliveryGroup: CartDeliveryGroup }>(graphqlQuery, {cart_id: cart.value.id});
    loadingCartDeliveryGroup.value = false;
    // const { getCartDeliveryGroup } = data;
    if (!errors){
      cartDeliveryGroup.value = data.getCartDeliveryGroup ?? null;
    }
  }

  const checkoutDeliveryGroup = ref({});

  const loadingCheckoutDeliveryGroup = ref(true);

  const getCheckoutDeliveryGroup = async () => {

    if (!totalItems.value){
      return;
    }

  const graphqlQuery = `
    query getCheckoutDeliveryGroup($cart_id: String){
      getCheckoutDeliveryGroup(cart_id: $cart_id) {
          delivery_groups {
            nogo
            standard {
              delivery_group_id
              delivery_group_name
              fulfillment_agency_name
              plan_type
              free_delivery_min_subtotal
              after_hours_time_start
              after_hours_delivery_charge
              delivery_charge_amount {
                charge
                location_surcharge
                after_hour_surcharge
              }
              available_dates
              mattress_disposal
              timeslots {
                id
                timeslot_start
                timeslot_end
                is_closed
              }
            }
            express {
              delivery_group_id
              delivery_group_name
              fulfillment_agency_name
              plan_type
              delivery_date
              after_hours_time_start
              after_hours_delivery_charge
              delivery_charge_amount {
                charge
                location_surcharge
                after_hour_surcharge
              }
              mattress_disposal
              timeslots {
                id
                timeslot_start
                timeslot_end
                is_closed
              }
            }
            items {
              item_id
              product_id
              product_name
              earliest_available_date {
                standard
                express
              }
            }
          }
        }
      }
  `;
    const { data: { getCheckoutDeliveryGroup } } = await query<{ getCheckoutDeliveryGroup: CheckoutDeliveryGroups }>(graphqlQuery, {cart_id: cart.value.id});
    // const { getCheckoutDeliveryGroup } = data;
    checkoutDeliveryGroup.value = merge({}, checkoutDeliveryGroup.value, getCheckoutDeliveryGroup);
    loadingCheckoutDeliveryGroup.value = false;
  }

  const timeslots = ref();

  const getDeliveryGroupTimeslotsForDate = async (delivery_group_id, delivery_date) => {

    if (!totalItems.value){
      return;
    }

  const graphqlQuery = `
    query getDeliveryGroupTimeslots($delivery_group_id: Int, $delivery_date: String, $cart_id: String){
      getDeliveryGroupTimeslots(delivery_group_id: $delivery_group_id, delivery_date: $delivery_date, cart_id: $cart_id) 
        {
          timeslots{
            id
            timeslot_start
            timeslot_end
            is_closed
          }
        }
      }
  `;
  const variables = {delivery_group_id: delivery_group_id, delivery_date: delivery_date, cart_id: cart.value.id}
    const { data: { getDeliveryGroupTimeslots } } = await query<{ getDeliveryGroupTimeslots: DeliveryGroupTimeslots }>(graphqlQuery, variables);
    // const { getCheckoutDeliveryGroup } = data;
    timeslots.value = merge({}, getDeliveryGroupTimeslots);
  }

  const delivery_quote = ref();

  const getDeliveryQuote = async (delivery_group_id) => {

    if (!totalItems.value){
      return;
    }

    const graphqlQuery = `
      query getDeliveryQuote($delivery_group_id: Int, $cart_id: String){
        getDeliveryQuote(delivery_group_id: $delivery_group_id, cart_id: $cart_id)
        {
          delivery_date
          delivery_time_start
          delivery_time_end
          remarks
          require_mattress_disposal
        }
      }
    `;
    const { data: { getDeliveryQuote } } = await query<{ getDeliveryQuote: DeliveryQuote }>(graphqlQuery, {delivery_group_id: delivery_group_id, cart_id: cart_id.value});
    delivery_quote.value = merge({}, delivery_quote.value, getDeliveryQuote);
  }

  const getFormattedDate = ( timestamp ) => {
    return moment(timestamp*1000, 'x').format("dddd, D MMMM YYYY");
  }

  const getDisplayDate = (timestamp) =>{
    const now = moment().format("YYYY-MM-DD");
    const formatTimestamp = moment(timestamp*1000, 'x').format("YYYY-MM-DD");
    const differentInDay = moment(formatTimestamp).diff(now, 'days'); //calcualte different between now and express earliest date
    if (differentInDay === 0){
      return "Today";
    }
    if (differentInDay === 1){
      return "Tomorrow";
    }
    else{
      return moment(timestamp*1000, 'x').format("dddd, D MMMM YYYY");
    }
     
  }

  const saveQuoteDeliveryInfoResponse = ref();

  const saveQuoteDeliveryInfo = async (quote_delivery_info) => {
    try {
      loading.value = true;
      if (!totalItems.value){
        return;
      }
  
    const graphqlQuery = `
      mutation saveQuoteDeliveryInfo($cart_id: String, $delivery_group_info: [DeliveryGroupInfo]){
        saveQuoteDeliveryInfo(cart_id: $cart_id, delivery_group_info: $delivery_group_info) {
          error
        }
      }
      `;
      const variables = {cart_id: cart_id.value, delivery_group_info: quote_delivery_info}
      const { data } = await query<{ saveQuoteDeliveryInfo: SaveQuoteDeliveryInfo }>(graphqlQuery, variables);
      const { saveQuoteDeliveryInfo } = data;
  
      saveQuoteDeliveryInfoResponse.value = merge({}, saveQuoteDeliveryInfoResponse.value, saveQuoteDeliveryInfo);
      // console.log(cart?.value)
      // const cartItemDelivery = `
      //   query cart($cartId: String!) {
      //     cart(cart_id:$cartId) {
      //       items {
      //         uid
      //         delivery_group {
      //           delivery_group_name
      //           fulfillment_agency_name
      //         }
      //       }
      //     }
      //   }
      // `;
      // const {data: cartItemDeliveryData} = await query<Cart>(cartItemDelivery, {cartId: cart_id.value});
      // setCart(cartItemDeliveryData);
      // // console.log(cartItemDeliveryData)

      // save shipping method to recalculate shipping charge
      // const shippingMethod = await loadShippingAddress();
      // Logger.debug('[Magento]: Defining the shipping method as:', JSON.stringify(shippingMethod, null, 2));
      
      // query to get available_shipping_methods if it is not found, typically on reload
      if (!cart?.value?.shipping_addresses[0]?.available_shipping_methods) {
        const graphqlQuery = `
          query cart($cartId: String!) {
            cart(cart_id:$cartId) {
              shipping_addresses {
                available_shipping_methods {
                  carrier_code
                  method_code
                }
              }
            }
          }`;
        const shippingAddressData = await query<{ cart: Cart }>(graphqlQuery, {cartId: cart.value.id});
        mergeCart(shippingAddressData.data.cart)
        // save shipping method to recalculate shipping charge
        await saveShippingMethod({
          shippingMethod: {
            carrier_code: shippingAddressData.data.cart?.shipping_addresses[0]?.available_shipping_methods[0].carrier_code,
            method_code: shippingAddressData.data.cart?.shipping_addresses[0]?.available_shipping_methods[0].method_code,
          },
        });
      }
      else{
        // save shipping method to recalculate shipping charge
        await saveShippingMethod({
          shippingMethod: {
            carrier_code: cart?.value?.shipping_addresses[0]?.available_shipping_methods[0].carrier_code,
            method_code: cart?.value?.shipping_addresses[0]?.available_shipping_methods[0].method_code,
          },
        });
      }
      
    }
    catch (err) {
      
    }
    finally {
      loading.value = false;
    }

  }

  const saveQuoteRemarks = async (remarks) => {
    if (!remarks){
      return; // return when no remarks
    }

    try {
      loading.value = true;
      if (!totalItems.value){
        return;
      }
  
    const graphqlQuery = `
      mutation saveQuoteRemarks($cart_id: String, $remarks: String){
        saveQuoteRemarks(cart_id: $cart_id, remarks: $remarks) {
          error
        }
      }
      `;
      const { data } = await query<{ saveQuoteRemarksInfo: SaveQuoteRemarksInfo }>(graphqlQuery, {cart_id: cart.value.id, remarks: remarks});
      const { saveQuoteRemarksInfo } = data;
  
      return saveQuoteRemarksInfo;
      
    }
    catch (err) {
      
    }
    finally {
      loading.value = false;
    }

  }

  const deliveryQuoteValidation = ref();

  const validateDeliveryQuote = async () => {

    if (!totalItems.value){
      return;
    }

  const graphqlQuery = `
    query validateDeliveryQuote($cart_id: String){
      validateDeliveryQuote (cart_id: $cart_id) 
        {
          status
        }
      }
  `;
    const { data: { validateDeliveryQuote } } = await query<{ validateDeliveryQuote: DeliveryQuoteValidation }>(graphqlQuery, {cart_id: cart.value.id});
    // const { getCheckoutDeliveryGroup } = data;
    deliveryQuoteValidation.value = merge({}, deliveryQuoteValidation.value, validateDeliveryQuote);
  }

  const getSelectedDeliveryQuote = async () => {
    const graphqlQuery = `
      query cart($cart_id: String!){
        cart (cart_id: $cart_id) 
          {
            items {
              uid
              delivery_group {
                delivery_group_name
                fulfillment_agency_name
              }
            }
          }
        }
    `;
    // get quote item delivery group info
    const { data } = await query<{ cart: Cart }>(graphqlQuery, {cart_id: cart.value.id}); 
    // merge to cart
    mergeCart(data?.cart);
  }

  return {
    getProductDelivery,
    cartDeliveryGroup,
    getCartDeliveryGroup,
    loadingCartDeliveryGroup,
    getFormattedDate, 
    getDisplayDate,
    getCheckoutDeliveryGroup,
    loadingCheckoutDeliveryGroup,
    checkoutDeliveryGroup,
    cart_id, 
    saveQuoteDeliveryInfo,
    saveQuoteDeliveryInfoResponse, 
    timeslots, 
    getDeliveryGroupTimeslotsForDate, 
    delivery_quote, 
    getDeliveryQuote, 
    deliveryQuoteValidation, 
    validateDeliveryQuote,
    loading,
    saveQuoteRemarks,
    getSelectedDeliveryQuote
  }
}


export default useFtDelivery;